import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
    Container,
    Switch,
    FormControlLabel,
    Tooltip,
    IconButton,
    DialogTitle, DialogContent, Dialog, DialogActions, DialogContentText
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Header from "../components/Header";
import AddIcon from '@mui/icons-material/Add';
import HelpIcon from '@mui/icons-material/Help';
import {useNavigate} from "react-router-dom";
import { useAuth } from "../components/UseAuth";
import axios from 'axios';
import { logEvent } from "firebase/analytics";
import {analytics} from "../firebase-config";
import { v4 as uuidv4 } from 'uuid';
import ReactGA from "react-ga4";

const PlayersPage = () => {
    const theme = useTheme();
    const [players, setPlayers] = useState(['']);
    const navigate = useNavigate();
    const [isNSFW, setIsNSFW] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const api_url = process.env.REACT_APP_API_URL
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [sessionID, setSessionID] = useState("");

    useEffect(() => {
        ReactGA.initialize("G-5XFD1H085J");
        const sessionID = uuidv4();
        localStorage.setItem('sessionID', sessionID);
        setSessionID(sessionID);
    }, [])

    const handleTooltipToggle = () => {
        setTooltipOpen(!tooltipOpen);
    };

    const handlePlayerChange = (index, event) => {
        const newPlayers = [...players];
        newPlayers[index] = event.target.value;
        setPlayers(newPlayers);
    };

    const addPlayerField = () => {
        setPlayers([...players, '']);
    };

    const handleNSFWChange = (event) => {
        setIsNSFW(event.target.checked);
    };

    const handleSubmit = async () => {
        if (!players.some(player => player.trim() !== '')) {
            setOpenDialog(true);
            return;
        }

            try {
                // Prepare the request headers
                const headers = {
                    "Content-Type": "application/json",
                };

                // Prepare the request body
                const body = {
                    user_id: sessionID,
                    player_names: players.join(','), // Convert array of player names to comma-separated string
                    nsfw: isNSFW,
                };

                // Perform the POST request using Axios
                const response = await axios.post(`${api_url}/create_game_session/`, body, { headers });
                logEvent(analytics, 'game_session_created', {
                    nsfw_enabled: isNSFW,
                    num_players: players.length
                });
                // console.log(response.data); // Handle success response
                navigate('/question'); // Navigate to questions page on success
            } catch (error) {
                console.error('Error:', error.response ? error.response.data : error.message); // Handle errors
            }


    };
    const handleClose = () => {
        setOpenDialog(false);
    };

    return (
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            <Header />
            <Box
                sx={{
                    bgcolor: theme.palette.primary.main,
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography variant="h1" sx={{ color: 'white', mb: 2 }}>
                    Get Started!
                </Typography>
                <Container
                    sx={{
                        height: '300px',
                        width: '80%',
                        bgcolor: theme.palette.secondary.main,
                        overflowY: 'auto',
                        borderRadius: '30px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        border: '2px solid white'
                    }}
                >
                    <FormControlLabel
                        control={<Switch checked={isNSFW} onChange={handleNSFWChange} />}
                        label={
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {isNSFW ? "NSFW ON" : "NSFW OFF"}
                                <Tooltip
                                    title="Not Safe For Work content isn't something you would play with your parents"
                                    placement="top"
                                    open={tooltipOpen}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                >
                                    <IconButton onClick={handleTooltipToggle} sx={{color: 'white'}}>
                                        <HelpIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        }
                        sx={{ mt: 2, color: '#063359', paddingBottom: '20px', paddingTop: '10px'  }}
                    />
                    <Typography variant="rules_body_white" sx={{ mb: 2 }}>
                        ENTER EACH PLAYERS NAME
                    </Typography>
                    {players.map((player, index) => (
                        <TextField
                            key={index}
                            value={player}
                            onChange={(event) => handlePlayerChange(index, event)}
                            variant="outlined"
                            placeholder="Player name"
                            sx={{
                                borderRadius: '15px', // Apply border-radius
                                width: '100%',
                                mb: 2,
                                backgroundColor: theme.palette.primary.main,
                                '& .MuiInputBase-input': {
                                    color: 'white', // Text color
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'transparent', // Remove border
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'transparent', // Remove border on hover
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'transparent', // Remove border on focus
                                    },
                                },
                            }}
                        />
                    ))}
                    <Button onClick={addPlayerField}  sx={{ color: theme.palette.primary.main, minWidth: 50 }}>
                        <AddIcon />
                    </Button>

                </Container>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleSubmit}
                    sx={{
                        mt: 2,
                        color: theme.palette.primary.main,
                        borderRadius: '30px',
                        fontWeight: 'bold',
                        fontSize: '6vw', // Increase base font size, will scale with the viewport width
                        fontFamily: 'Myriad',
                        textTransform: 'none',
                        boxShadow: 'none',
                        // minWidth: '260px',
                        width: '80%',
                        // width: '50vw',
                        textAlign: 'center',
                        paddingTop: '1rem',
                        '&:hover': {
                            boxShadow: 'none',
                        },
                    }}
                >
                    DRINKS UP
                </Button>
            </Box>
            <Dialog open={openDialog} onClose={handleClose}>
                <DialogTitle>{"Missing Player Names"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter at least one player's name before proceeding.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default PlayersPage;
