import React, {useState} from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Box,
    Grid,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Typography,
    Button
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../assets/logo/logo-text.png';
import { signOut } from "firebase/auth";
import {Link, useNavigate} from "react-router-dom";
import { auth } from '../firebase-config';

const Header = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const navigate = useNavigate();

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setIsDrawerOpen(open);
    };


    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate("/login");
        } catch (error) {
            console.error("Error logging out: ", error.message);
        }
    };

    return (
        <AppBar position="fixed" sx={{ background: 'transparent', boxShadow: 'none', color: '#01DADE' }}>

        <Toolbar disableGutters>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ padding: '0 20px' }} // Add padding here
                >
                    <Grid item>
                    <img src={logo} alt="Text Logo" style={{ height: '40px', paddingTop: '30px' }} />
                    </Grid>
                    <Grid item>
                <IconButton
                    edge="end"
                    color="inherit"
                    aria-label="menu"
                    sx={{ flexGrow: 0, paddingTop: '20px', color: '#01DADE' }}
                    onClick={toggleDrawer(true)}
                >
                    <MenuIcon />
                </IconButton>
                    </Grid>

                </Grid>
            </Toolbar>

            <Drawer
                anchor="right"
                open={isDrawerOpen}
                onClose={toggleDrawer(false)}
            >
                <Box
                    sx={{
                        width: 250,
                        backgroundColor: '#01DADE',
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <List>
                        <ListItem key="Home">
                            <Button component={Link}
                                    to="/">
                                <Typography variant="hamburger_text">
                                    HOME
                                </Typography>
                            </Button>
                        </ListItem>
                        <ListItem key="Log Out" onClick={handleLogout}>
                            <Button>
                                <Typography variant="hamburger_text">
                                    LOG OUT
                                </Typography>
                            </Button>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>


        </AppBar>

    );
};

export default Header;
