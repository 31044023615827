import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route, HashRouter} from "react-router-dom";
import HomePage from "./pages/HomePage";
import {ThemeProvider} from "@mui/material";
import theme from './theme/theme';
import QuestionPage from "./pages/QuestionPage";
import PlayersPage from "./pages/PlayersPage";
import LoadingScreen from "./pages/LoadingScreen";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import {AuthProvider} from "./components/UseAuth";
import ProtectedRoute from "./components/ProtectedRoute";
import RulesPage from "./pages/RulesPage";
import LoginPageNoAuth from "./pages/LoginPageNoAuth";
import HomePageNoAuth from "./pages/HomePageNoAuth";
import RulesPageNoAuth from "./pages/RulesPageNoAuth";
import PlayersPageNoAuth from "./pages/PlayersPageNoAuth";
import QuestionPageNoAuth from "./pages/QuestionPageNoAuth";

function App() {

  return (
      <ThemeProvider theme={theme}>
        <HashRouter>
            {/*<AuthProvider>*/}
              <Routes>
                  <Route path="/login" element={<LoginPageNoAuth/>}/>
                  {/*<Route element={<ProtectedRoute/>}>*/}
                      <Route path="/" element={<HomePageNoAuth/>}/>
                      <Route path="/getstarted" element={<PlayersPageNoAuth/>}/>
                      <Route path="/loading" element={<LoadingScreen/>}/>
                      <Route path="/question" element={<QuestionPageNoAuth/>}/>
                      <Route path="/rules" element={<RulesPageNoAuth/>}/>
                  {/*</Route>*/}

              </Routes>
            {/*</AuthProvider>*/}
        </HashRouter>
      </ThemeProvider>
  );
}


export default App;
