import React, { useState, useEffect } from 'react';

const LoadingScreen = ({ isLoading }) => {
    const [loadingGif, setLoadingGif] = useState('');

    useEffect(() => {
        // Select a random gif number between 1 and 4 when isLoading is true
        if (isLoading) {
            const gifNumber = Math.floor(Math.random() * 4) + 1; // Random number between 1 and 4
            setLoadingGif(require(`../assets/gifs/${gifNumber}.gif`)); // Adjust the path if necessary
        }
    }, [isLoading]);

    if (!isLoading) {
        return null; // Don't render anything if not loading
    }

    return (
        <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 9999, backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <img
                src={loadingGif}
                alt="Loading..."
                style={{
                    width: '100vw',
                    height: '100vh',
                    objectFit: 'cover', // This will cover the whole screen with the gif, potentially cropping it
                }}
            />
        </div>
    );
};

export default LoadingScreen;
