import React, {useEffect, useState} from 'react';
import {Box, Button, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import Header from "../components/Header";
import LoadingScreen from "./LoadingScreen";
import axios from "axios";
import {useAuth} from "../components/UseAuth";
import CustomDialog from "../components/CustomDialog";
import {useNavigate} from "react-router-dom";
import ChallengeDialog from '../components/ChallengeDialog';
const QuestionPage = () => {
    const theme = useTheme();
    const [isChallengeDialogOpen, setIsChallengeDialogOpen] = useState(false);
    const [challengeText, setChallengeText] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [questions, setQuestions] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [background, setBackground] = useState('');
    const textBoxColors = ['01DADE', '5178FF', '90F7C3', '9D89FF', 'EAA7FD', 'F6EBF8', 'FEEE77', 'FF82B3'];
    const [textBoxColor, setTextBoxColor] = useState('')
    const [apiCalled, setApiCalled] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogText, setDialogText] = useState('');
    const [dialogTitle, setDialogTitle] = useState('ERROR');
    const api_url = process.env.REACT_APP_API_URL
    const [sessionID, setSessionID] = useState("");

    const handleOpenDialogWithError = (errorMessage) => {
        setDialogText(errorMessage);
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsChallengeDialogOpen(false);

    };
    const handleCloseChallengeDialog = () => {
        setIsChallengeDialogOpen(false); // Close the challenge dialog
        fetchMoreQuestions(); // Fetch more questions right after closing the dialog
    };


    const handleSubmitDialog = () => {
        // console.log('Submit action');
        setIsDialogOpen(false);
        navigate('/');

    };
    const fetchBackgroundAndQuestions = async () => {

        const questionsEndpoint = `${api_url}/api/questions/`;
        const challengesEndpoint = `${api_url}/api/challenges/`;

            try {
                selectBackgroundNotInColors();
                const headers = {
                    "Content-Type": "application/json",

                };
                const body = {
                    user_id: localStorage.getItem('sessionID'),
                };
                // Fetch questions and challenges concurrently
                const [questionsResponse, challengesResponse] = await Promise.all([
                    axios.post(questionsEndpoint,  body,  { headers }),
                    axios.post(challengesEndpoint,  body, { headers }),
                    // mimic for aesthetic
                    new Promise(resolve => setTimeout(resolve, 2000))
                ]);
                setQuestions(questionsResponse.data.questions);
                setChallengeText(challengesResponse.data.challenge)

            } catch (error) {
                handleOpenDialogWithError("We have run out of questions and challenges. Return home to restart.");
                console.error('Error fetching questions or challenges');

            } finally {
                setLoading(false);
            }




    };
    useEffect(() => {
        setSessionID(localStorage.getItem('sessionID'));
        console.log(`session ID ${localStorage.getItem('sessionID')}`)
        if (!apiCalled) {
            fetchBackgroundAndQuestions();
            setApiCalled(true);

        }
    }, []);


    const selectBackgroundNotInColors = () => {

        const backgroundsPath = 'assets/backgrounds/';

        const backgrounds = [
            // '01DADE-01.png','01DADE-02.png','01DADE-02.png',
            '5178FF-01.png','5178FF-02.png','5178FF-03.png','5178FF-04.png',
            '90F7C3-01.png','90F7C3-02.png','90F7C3-03.png',
            '9D89FF-01.png','9D89FF-02.png','9D89FF-03.png',
            'EAA7FD-01.png','EAA7FD-02.png','EAA7FD-03.png',
            'F6EBF8-01.png','F6EBF8-02.png','F6EBF8-03.png','F6EBF8-04.png',
            'FEEE77-01.png','FEEE77-02.png','FEEE77-03.png',
            'FF82B3-01.png','FF82B3-02.png','FF82B3-03.png',
        ];
        const selectedBackgroundIndex = Math.floor(Math.random() * backgrounds.length);
        const selectedBackground = backgrounds[selectedBackgroundIndex];
        setBackground(`${backgroundsPath}${selectedBackground}`);

        const selectedBgColorCode = selectedBackground.split('-')[0];
        // console.log(`image: ${selectedBgColorCode}`)
        let randomColorIndex = Math.floor(Math.random() * textBoxColors.length);
        let randomColor = textBoxColors[randomColorIndex];
        // console.log(`Random colour: ${randomColor}`)
        while (randomColor === selectedBgColorCode) {
            randomColorIndex = Math.floor(Math.random() * textBoxColors.length);
            randomColor = textBoxColors[randomColorIndex];
        }
        // console.log(`Random colour: ${randomColor}`)
        setTextBoxColor(`#${randomColor}`);
    };

    const handleNextQuestion = async () => {
        const nextQuestionIndex = currentQuestion + 1;
        if (nextQuestionIndex >= questions.length) {
            // If the current question is the last one, fetch more questions
            setIsChallengeDialogOpen(true)

        } else {
            setCurrentQuestion(nextQuestionIndex);
        }
    };

    const fetchMoreQuestions = async () => {
        setLoading(true); // Show the loading screen while fetching
            try {
                const headers = {
                    "Content-Type": "application/json",
                };
                const body = {
                    user_id: localStorage.getItem('sessionID'),
                };
                selectBackgroundNotInColors()
                const questionsEndpoint = `${api_url}/api/questions/`;
                const challengesEndpoint = `${api_url}/api/challenges/`;
                const [response, challengesResponse] = await Promise.all([
                    await axios.post(questionsEndpoint, body,{ headers }),
                    await axios.post(challengesEndpoint, body,{ headers }),
                    new Promise(resolve => setTimeout(resolve, 2000))
                ]);
                // console.log(response)


                // Add the new questions to the existing list
                setCurrentQuestion(questions.length);

                // Add the new questions to the existing list
                setQuestions(prevQuestions => [...prevQuestions, ...response.data.questions]);
                setChallengeText(challengesResponse.data.challenge);

            } catch (error) {
                handleOpenDialogWithError("We have run out of questions and challenges. Return home to restart.");
                console.error('Error fetching questions or challenges');
            } finally {
                setLoading(false);
            }

    };
    return (
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            <Header />
            {loading && <LoadingScreen isLoading={loading} />}
            <Box
                sx={{
                    backgroundImage: `url(${background})`,
                    height: '100vh',
                    width: '100vw',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundSize: 'cover',
                }}
            >
                <ChallengeDialog
                    open={isChallengeDialogOpen}
                    onClose={handleCloseChallengeDialog}
                    challengeText={challengeText}
                />

                <CustomDialog
                    isOpen={isDialogOpen}
                    onClose={handleCloseDialog}
                    onSubmit={handleSubmitDialog}
                    dialogText={dialogText}
                    dialogTitle={dialogTitle}
                    dialogColor="#FFFFF"
                    textColor="#000"
                />

                <Box
                    sx={{
                        backgroundColor: textBoxColor,
                        p: 2,
                        borderRadius: '30px',
                        minWidth: '260px',
                        minHeight: '200px',
                        maxWidth: '80%',
                        width: '50vw',
                        mb: 2,
                    }}
                >
                    <Typography variant="question_body">
                        {questions[currentQuestion]}
                    </Typography>
                </Box>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleNextQuestion}
                    sx={{ mt: 2,
                        color: '#111218',
                        borderRadius: '30px',
                        fontWeight: 'bold',
                        fontSize: '6vw', // Increase base font size, will scale with the viewport width
                        fontFamily: 'Myriad',
                        textTransform: 'none',
                        boxShadow: 'none',
                        minWidth: '260px',
                        maxWidth: '80%',
                    }}
                >
                    NEXT
                </Button>
            </Box>
        </Box>
    );
};


export default QuestionPage;
