import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

const CustomDialog = ({ isOpen, onClose, onSubmit, dialogTitle, dialogText, dialogColor = 'primary', textColor = 'secondary' }) => {
    return (
        <Dialog open={isOpen} onClose={onClose} aria-labelledby="custom-dialog-title" aria-describedby="custom-dialog-description">
            <DialogTitle id="custom-dialog-title" sx={{ backgroundColor: dialogColor, color: '#de0101' }}>
                {dialogTitle}
            </DialogTitle>
            <DialogContent sx={{ backgroundColor: dialogColor }}>
                <DialogContentText id="custom-dialog-description" sx={{ color: '#063359' }}>
                    {dialogText}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ backgroundColor: dialogColor }}>
                <Button onClick={onClose} variant="outlined"
                        color="button_red" >CANCEL</Button>
                <Button onClick={onSubmit} variant="outlined"
                        color="button_green" autoFocus>
                    HOME
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CustomDialog;
