import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth,  } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyA4s87vlT76HCT7Z0d9-LdHqY14QeEzzSs",
    authDomain: "drinks-up-web.firebaseapp.com",
    projectId: "drinks-up-web",
    storageBucket: "drinks-up-web.appspot.com",
    messagingSenderId: "534345624259",
    appId: "1:534345624259:web:6f94159e76511e1c9a1076",
    measurementId: "G-5XFD1H085J"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);

