import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Logo from '../assets/logo/logo-trans.png';
import Header from "../components/Header";
import LoadingScreen from "./LoadingScreen";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";

const HomePage = () => {
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const [showConsentBanner, setShowConsentBanner] = useState(localStorage.getItem('consentGiven') !== 'true');
    const [consentGiven, setConsentGiven] = useState(false);
    useEffect(() => {
        ReactGA.initialize("G-5XFD1H085J");
        if (!localStorage.getItem('consentGiven')) {
            // Default GA settings

                ReactGA.gtag("consent", "update", {
                    ad_storage: "granted",
                    ad_user_data: "granted",
                    ad_personalization: "granted",
                    analytics_storage: "granted",
                });

            localStorage.setItem('consentGiven', 'true');
        }
    }, [])



    return (
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            <Header />
            {isLoading && <LoadingScreen isLoading={isLoading} />}

            <Box
                sx={{
                    bgcolor: theme.palette.primary.main,
                    height: '100vh',
                    width: '100vw',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // p: 2,
                }}
            >

                <img src={Logo} alt="Drinks Up Logo" style={{ maxWidth: '80%', marginBottom: '3rem', marginRight: '1.3rem' }} />
                <Stack direction="column" spacing={2} alignItems="center">
                    <Button
                        component={Link}
                        to="/getstarted"
                        variant="contained"
                        color="secondary"
                        sx={{
                            color: '#063359',
                            borderRadius: '30px',
                            fontWeight: 'bold',
                            fontSize: '6vw',
                            fontFamily: 'Myriad',
                            textTransform: 'none',
                            boxShadow: 'none',
                            minWidth: '260px',
                            maxWidth: '80%',
                            width: '50vw',
                            textAlign: 'center',
                            paddingTop: '1rem',
                            '&:hover': {
                                boxShadow: 'none',
                            },
                        }}

                    >
                        PLAY
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        component={Link}
                        to="/rules"
                        sx={{
                            color: '#063359',
                            borderRadius: '30px',
                            fontWeight: 'bold',
                            fontSize: '6vw',
                            fontFamily: 'Myriad',
                            textTransform: 'none',
                            boxShadow: 'none',
                            minWidth: '260px',
                            maxWidth: '80%',
                            width: '50vw',
                            textAlign: 'center',
                            paddingTop: '1rem',
                            '&:hover': {
                                boxShadow: 'none',
                            },
                        }}
                    >
                        RULES
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
};

export default HomePage;