import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        h1: {
            fontSize: '4.5rem',
            color: '#ffffff',
            fontFamily: 'Bright'
        },
        h1_challenge: {
            fontSize: '3rem',
            color: '#063359',
            fontFamily: 'Bright'
        },
        question_body: {
            fontSize: '1.6rem',
            color: '#111218',
            fontFamily: 'Myriad'
        },
        question_body_white: {
            fontSize: '1.6rem',
            color: '#ffffff',
            fontFamily: 'Myriad',
            textAlign: 'justify'
        },
        rules_body_white: {
            fontSize: '1.3rem',
            color: '#ffffff',
            fontFamily: 'Myriad',
            textAlign: 'justify'
        },
        tos_white: {
            fontSize: '0.8rem',
            color: '#ffffff',
            fontFamily: 'Myriad',
            textAlign: 'justify'
        },
        hamburger_text: {
            fontSize: '2.6rem',
            color: '#111218',
            fontFamily: 'Bright'

        },
    },
    palette: {

        primary: {
            main: '#063359',
        },
        secondary: {
            main: '#01DADE',
        },
        button_green: {
            main: '#1a6900'
        },
        button_red: {
            main: '#de0101'
        },
        button_grey: {
            main: '#5e5e5e'
        }


    },

});

export default theme;
