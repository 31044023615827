import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Typography, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Logo from '../assets/round-over-icon.png';

const ChallengeDialog = ({ open, onClose, challengeText }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="challenge-dialog-title"
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: '16px',
                    backgroundColor: '#01DADE',
                },
            }}
        >
            <DialogTitle
                sx={{
                    m: 0,
                    p: 2,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    backgroundColor: '#01DADE',
                }}
            >
                <IconButton onClick={onClose} aria-label="close" sx={{ color: '#FFF' }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ backgroundColor: '#01DADE', pt: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img src={Logo} alt="Logo" style={{ maxWidth: '100px', marginBottom: '30px' }} />
                    <Typography variant="h1_challenge" component="div" marginBottom='20px'>
                        ROUND OVER!
                    </Typography>
                    <Typography variant='question_body' color="#FFF" margin='10px'>
                        {challengeText}
                    </Typography>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default ChallengeDialog;
