import React, {useEffect, useState} from 'react';
import { signInWithEmailAndPassword, getIdToken, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useTheme } from '@mui/material/styles';
import { auth } from '../firebase-config';
import {
    Button,
    TextField,
    Box,
    Typography,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack, FormControlLabel, Checkbox
} from '@mui/material';
import {Link, useNavigate} from "react-router-dom";
import Logo from "../assets/logo/logo-trans.png";
import { v4 as uuidv4 } from 'uuid';


const LoginPage = () => {
    const [tosOpen, setTosOpen] = useState(false);
    const theme = useTheme();
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const navigate = useNavigate();
    const [isDeviceCompatible, setIsDeviceCompatible] = useState(true);

    const handlePlayNow = async () => {
        try {
            // const sessionID = uuidv4();
            // localStorage.setItem('sessionID', sessionID);
            navigate('/')

        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        // Check if device width is less than or equal to 600px, typical for phones
        const checkDeviceWidth = () => {
            if(window.innerWidth > 600) {
                setIsDeviceCompatible(false);
            }
        };

        // Call on mount
        checkDeviceWidth();

        // Add resize listener to handle dynamic changes (e.g., orientation change)
        window.addEventListener('resize', checkDeviceWidth);

        // Cleanup
        return () => window.removeEventListener('resize', checkDeviceWidth);
    }, []);

    const handleToggleTos = () => {
        setTosOpen(!tosOpen);
    };
    const handleAgreeToTerms = (event) => {
        setAgreeToTerms(event.target.checked);
    };


    return (
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            <Dialog open={!isDeviceCompatible} aria-labelledby="responsive-dialog-title">
                <DialogTitle id="responsive-dialog-title">
                    {"Use a Phone for Access"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To sign in, please use a mobile phone to access Drinks Up.
                    </DialogContentText>
                </DialogContent>

            </Dialog>

            <Box
                sx={{
                    bgcolor: theme.palette.primary.main,
                    height: '100vh',
                    width: '100vw',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // p: 2,
                }}
            >

                <img src={Logo} alt="Drinks Up Logo" style={{ maxWidth: '60%', marginBottom: '3rem', marginRight: '1.3rem' }} />
                <Stack direction="column" spacing={2} alignItems="center">

                    <Button
                        onClick={handlePlayNow}
                        variant="contained"
                        color="secondary"
                        disabled={!agreeToTerms}
                        sx={{
                            color: '#063359',
                            borderRadius: '30px',
                            fontWeight: 'bold',
                            fontSize: '5vw',
                            fontFamily: 'Myriad',
                            textTransform: 'none',
                            boxShadow: 'none',
                            minWidth: '260px',
                            maxWidth: '80%',
                            width: '50vw',
                            textAlign: 'center',
                            paddingTop: '1rem',
                            '&:hover': {
                                boxShadow: 'none',
                            },
                        }}

                    >
                        PLAY NOW
                    </Button>
                    <FormControlLabel
                        control={<Checkbox
                            checked={agreeToTerms}
                            onChange={handleAgreeToTerms}
                            sx={{
                                color: 'gray',
                                '&.Mui-checked': {
                                    color: 'secondary.main',
                                },
                            }}
                        />}
                        label={
                            <Typography variant='tos_white'>
                                I agree to the <Button onClick={handleToggleTos} style={{ textTransform: 'none', padding: 0, color: '#01d8dc', fontSize: '12px' }}>Terms of Service</Button> and the use of cookies.
                            </Typography>
                        }
                    />

                    <Dialog
                        open={tosOpen}
                        onClose={handleToggleTos}
                        aria-labelledby="scroll-dialog-title"
                        aria-describedby="scroll-dialog-description"
                        fullWidth
                        maxWidth="sm"
                    >
                        <DialogTitle id="scroll-dialog-title">Terms of Service</DialogTitle>
                        <DialogContent dividers={true}>
                            <DialogContentText
                                id="scroll-dialog-description"
                                component="div"
                                sx={{ height: '50vh', overflow: 'auto' }}
                            >
                                <Typography paragraph>1. Introduction</Typography>
                                <Typography paragraph>Welcome to Drinks Up, a mobile application designed to provide entertaining drinking games. This document, the Terms of Service ("ToS"), is a legal agreement between the Drinks Up development team ("we", "us", or "our") and you ("user", "you"). By using Drinks Up, you agree to be bound by the terms outlined herein.</Typography>

                                <Typography paragraph>2. Age Requirement</Typography>
                                <Typography paragraph>You must be of legal drinking age in your jurisdiction to use Drinks Up. By agreeing to these Terms, you represent and warrant that you meet this age requirement. It is your responsibility to ensure that your use of Drinks Up complies with all applicable laws and regulations regarding age restrictions.</Typography>

                                <Typography paragraph>3. Use of Service</Typography>
                                <Typography paragraph>Drinks Up is provided solely for your personal, non-commercial use. You agree not to use Drinks Up for any unlawful purpose or in any way that might harm, damage, or disparage any other party. While using the app, you must comply with all applicable laws and regulations.</Typography>

                                <Typography paragraph>4. Disclaimer of Liability</Typography>
                                <Typography paragraph>We provide Drinks Up for entertainment purposes only. We are not responsible for any consequences that may arise from the use of our app, including but not limited to accidents or injuries. You assume all risk associated with the consumption of alcohol. We do not promote excessive drinking and advise you to drink responsibly.</Typography>

                                <Typography paragraph>5. User Responsibilities</Typography>
                                <Typography paragraph>As a user of Drinks Up, you are responsible for your behavior while using the app. You agree to conduct yourself in a manner that is not harmful to yourself, other users, or any third parties. You acknowledge that failing to drink responsibly and in accordance with local laws concerning alcohol consumption is entirely at your own risk.</Typography>

                                <Typography paragraph>6. Changes to Terms</Typography>
                                <Typography paragraph>We reserve the right to modify these Terms at any time. Changes will become effective immediately upon posting to the app or website. Your continued use of Drinks Up after any such changes constitutes your acceptance of the new Terms.</Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleToggleTos} color="primary">Close</Button>
                        </DialogActions>
                    </Dialog>

                </Stack>
            </Box>
        </Box>

    );
};

export default LoginPage;
