import React from 'react';
import {Box, Button, Typography} from '@mui/material';

import Header from "../components/Header";

import {Link} from "react-router-dom";
const RulesPage = () => {

    return (
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            <Header />

            <Box
                sx={{
                    backgroundImage: `url('assets/backgrounds/5178FF-01.png')`,
                    height: '100vh',
                    width: '100vw',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundSize: 'cover',
                }}
            >
                <Box
                    sx={{
                        backgroundColor: '#01DADE',
                        p: 2,
                        borderRadius: '30px',
                        minWidth: '300px',
                        minHeight: '400px',
                        maxWidth: '80%',
                        maxHeight: '60%',
                        width: '50vw',
                        mb: 2,
                        display: 'flex',  // Use flex to align children
                        flexDirection: 'column', // Stack children vertically
                        alignItems: 'center',  // Center-align children horizontally
                        justifyContent: 'center',  // Center-align children vertically
                    }}
                >
                    <img src="assets/glass.png" alt="Round Over Icon" style={{
                        marginBottom: '10px', height: '80px'
                    }}/>
                    <Typography variant="rules_body_white">
                        {'Drinks Up is a group drinking game that provides players with prompts causing players to drink. Players respond to prompts in rounds. Each round concludes with a challenge that eliminates one player. The game continues until only one player remains. The winner nominates another player to down their drink.'}
                    </Typography>
                </Box>
                <Button
                    variant="contained"
                    color="secondary"
                    component={Link}
                    to="/"
                    sx={{
                        mt: 2,
                        color: '#ffffff',
                        borderRadius: '30px',
                        fontWeight: 'bold',
                        fontSize: '6vw', // Increase base font size, will scale with the viewport width
                        fontFamily: 'Myriad',
                        textTransform: 'none',
                        boxShadow: 'none',
                        minWidth: '320px',
                        maxWidth: '80%',
                    }}
                >
                    BACK
                </Button>
            </Box>
        </Box>
    )
}


export default RulesPage;
